
* {
    margin: 0;
    padding: 0;
}

body{
    background-image: url('/assets/—Pngtree—simple-blue.png') !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: center !important;
}

li {
    font-size: xx-large;
    &:hover{
        text-decoration: underline;
    }
    &:active{
        font-weight: bolder;
    }
}